import { Node } from 'tiptap'
import { replaceText } from "tiptap-commands"
import SuggestionsPlugin from 'tiptap-extensions/src/plugins/Suggestions';


function nodeInputRule(regexp, type, getAttrs) {
  return new prosemirrorInputrules.InputRule(regexp, (state, match, start, end) => {
    const attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs;
    const {
      tr
    } = state;

    if (match[0]) {
      tr.replaceWith(start, end, type.create(attrs));
    }

    return tr;
  });
}

var prosemirrorInputrules = require('prosemirror-inputrules');

export default class Tag extends Node {
  get name() {
    return 'tag'
  }

  get schema() {
    return {
      attrs: {
        label: "",
      },
      group: 'inline',
      inline: true,
      selectable: true,
      atom: true,
      toDOM: node => [
        'span',
        {
          class: "tag",
        },
        `${node.attrs.label}`,
      ],
      parseDOM: [
        {
          tag: 'span.tag',
          getAttrs: dom => {
            const label = dom.innerHTML;
            return { label }
          },
        },
      ],
    }
  }

  get view() {
    return {
      // there are some props available
      // `node` is a Prosemirror Node Object
      // `updateAttrs` is a function to update attributes defined in `schema`
      // `view` is the ProseMirror view instance
      // `options` is an array of your extension options
      // `selected` is a boolean which is true when selected
      // `editor` is a reference to the TipTap editor instance
      // `getPos` is a function to retrieve the start position of the node
      // `decorations` is an array of decorations around the node
      props: ['view', 'node', 'getPos'],
      data() {
        return { preEmpty: false, isInit: false }
      },
      mounted() {
        this.$nextTick(() => {
          if (!this.$el.previousSibling) {
            this.preEmpty = true;
          }
          this.isInit = true;
        })
      },
      template: `
        <span class="tag" v-show="isInit" :class="{'pre-empty':preEmpty}" :contenteditable="false">{{this.node.attrs.label}}</span>
      `,
    }
  }

  inputRules({ type }) {
    return [
      nodeInputRule(/(#[^#]+?)(?:\s)$/, type, match => {
        const [, label] = match;
        return {
          label
        }
      }),
    ]
  }

  insertTag(range, attrs, schema) {
    const nodeType = schema.nodes[this.name]
    return replaceText(range, nodeType, attrs)
  }

  get plugins() {
    return [
      SuggestionsPlugin({
        command: ({ range, attrs, schema }) => this.insertTag(range, attrs, schema),
        appendText: '',
        matcher: {
          char: '#',
          allowSpaces: false,
          startOfLine: false,
        },
        items: this.options.getItems,
        onEnter: this.options.onEnter,
        onChange: this.options.onChange,
        onExit: this.options.onExit,
        onKeyDown: this.options.onKeyDown,
        onFilter: this.options.onFilter,
        suggestionClass: "tag-suggestion",
      }),
    ]
  }

}
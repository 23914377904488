<template>
  <div class="app">
    <aside :class="{ 'hide-in-mobile': !showSummary }">
      <div class="aside-container">
        <section>
          <div class="summary">
            <div class="data">
              <div class="number">{{ this.posts.length }}</div>
              <div class="type">MEMO</div>
            </div>
            <div class="data">
              <div class="number">{{ this.tags.length }}</div>
              <div class="type">TAG</div>
            </div>
            <div class="data">
              <div class="number">{{ this.days.length }}</div>
              <div class="type">DAY</div>
            </div>
          </div>
          <div class="graph">
            <div class="week" v-for="week in graphData" :key="week.id">
              <div
                class="day"
                v-for="day in week.days"
                :key="day.day"
                :class="{ today: day.isToday }"
                :style="{ opacity: 0.2 + day.count / 12.5 }"
              ></div>
            </div>
          </div>
        </section>
        <section>
          <div class="positions">
            <div
              class="tag follow-up"
              :class="{ active: filter.status === Status.INBOX }"
              @click="filter.status = Status.INBOX"
            >
              草稿箱
            </div>
            <div
              class="tag follow-up"
              :class="{ active: filter.status === Status.DELETED }"
              @click="filter.status = Status.DELETED"
            >
              废纸篓
            </div>
          </div>
        </section>
        <section>
          <h3 class="title">标签</h3>
          <div class="tags">
            <div
              class="tag follow-up"
              v-for="tag in tags"
              :key="tag"
              :class="{ active: filter.tag === tag }"
              @click="
                filter.tag === tag ? (filter.tag = '') : (filter.tag = tag)
              "
            >
              # {{ tag.substring(1) }}
            </div>
          </div>
        </section>
      </div>
    </aside>
    <main>
      <div class="main-container">
        <header>
          <span @click.stop="showSummary = !showSummary">
            <svg-icon name="menu" class="icon-menu" />
          </span>
          <span
            @click.stop="
              filter.tag ? (filter.tag = '') : (showSummary = !showSummary)
            "
            >Memos {{ filter.tag }}</span
          >
        </header>
        <div class="input-container text-card">
          <editor
            keep-alive
            :init-content="initContent"
            :tag-suggestions="tags"
            @submit="create"
            @change="(content) => cache(0, content)"
            ref="input"
          ></editor>
        </div>
        <transition name="fade" mode="out-in">
          <div
            class="posts-container"
            v-if="renderDatas.length !== 0 || loading"
          >
            <transition-group name="post-list">
              <div v-for="day in renderDatas" :key="day.day">
                <label class="time">{{ day.day | formatTimestamp }}</label>
                <transition-group name="post-list">
                  <div v-for="(post, index) in day.posts" :key="post.id">
                    <context-menu class="post-item text-card">
                      <div
                        class="editor"
                        @dblclick="
                          !post.isEditing && $set(post, 'isEditing', true)
                        "
                      >
                        <editor
                          :ref="post.id"
                          :readonly="!post.isEditing"
                          :init-content="post.cache ? post.cache : post.content"
                          :tag-suggestions="tags"
                          @cancel="cancelEdit(post)"
                          @submit="update(post, index, $event)"
                          @change="(content) => cache(post.id, content)"
                        >
                        </editor>
                      </div>
                      <template #context>
                        <span
                          @click="$set(post, 'isEditing', true)"
                          v-if="!post.isEditing"
                        >
                          <svg-icon name="edit" /> 编辑
                        </span>

                        <span
                          @click="remove(post, index)"
                        >
                          <svg-icon name="delete" />
                          {{ filter.status === Status.INBOX ? "丢弃" : "删除" }}
                        </span>
                      </template>
                    </context-menu>
                  </div>
                </transition-group>
              </div>
            </transition-group>
          </div>

          <div class="posts-placeholder" v-else>
            <div v-if="filter.status === Status.INBOX">
              <img src="@/assets/images/ideas.svg" alt="" />
            </div>
            <div v-else-if="filter.status === Status.DELETED">
              <img src="@/assets/images/clean.svg" alt="" />
              <label>NOTHING</label>
            </div>
          </div>
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import { Posts } from "@/api/NASA";
import Editor from "@/components/Editor/index";
import ContextMenu from "@/components/ContextMenu.vue";
import Cache from "@/utils/Cache";
const Status = {
  INBOX: 0,
  PUBLISH: 1,
  DELETED: 2,
};
export default {
  components: {
    Editor,
    ContextMenu,
  },
  data() {
    return {
      initContent: Cache.get(0),
      posts: [],
      days: [],
      tags: [],
      postInEditing: null,
      showSummary: false,
      loading: true,
      asideHeight: "auto",
      filter: {
        tag: "",
        day: "",
        status: Status.INBOX,
      },
      Status,
    };
  },
  filters: {
    formatTimestamp(v) {
      let time = new Date(v);
      return `${time.getMonth() + 1}月${time.getDate()}日`;
    },
  },
  computed: {
    graphData() {
      let data = [];
      let today = new Date(new Date().toISOString());
      let todayDateStr = today.toISOString().substring(0, 10);
      let dayInMs = 24 * 60 * 60 * 1000;
      let nextMondayInMs = today.getTime() + (7 - today.getDay() + 1) * dayInMs;
      let weekCount = 13;
      let map = {};
      for (let i = 0; i < this.days.length; i++) {
        const element = this.days[i];
        map[element.day] = element.count;
      }
      for (let i = 0; i < weekCount; i++) {
        data[i] = { id: i, days: [] };
        for (let j = 0; j < 7; j++) {
          let day = new Date(
            nextMondayInMs - dayInMs * (7 * (weekCount - i) - j)
          )
            .toISOString()
            .substring(0, 10);
          data[i].days.push({
            day,
            count: map[day] ? map[day] : 0,
            isToday: day === todayDateStr,
          });
        }
      }
      return data;
    },
    renderDatas() {
      let data = [];
      if (this.posts) {
        this.posts.forEach((item) => {
          if (
            (!this.filter.tag ||
              (item.content &&
                item.content.indexOf(`>${this.filter.tag}<`) >= 0)) &&
            item.status === this.filter.status
          ) {
            let dayStr = item.postTime.substring(0, 10);
            let index = data.findIndex((item) => item.day === dayStr);
            if (index < 0) {
              data.push({
                day: dayStr,
                posts: [item],
              });
            } else {
              data[index].posts.push(item);
            }
          }
        });
      }
      return data;
    },
  },
  watch: {
    posts(value) {
      let tagRegex = /<span.*?class="tag.*?>(#.+?)(?:<)/g;
      this.tags = [];
      this.days = [];
      value.forEach((post) => {
        post.cache = Cache.get(post.id);
        if (post.content && post.status !== Status.DELETED) {
          let match;
          while ((match = tagRegex.exec(post.content)) !== null) {
            let tag = match[1].trim();
            if (this.tags.indexOf(tag) < 0) {
              this.tags.push(tag);
            }
          }
        }
        let dayStr = post.postTime.substring(0, 10);
        let index = this.days.findIndex((item) => item.day === dayStr);
        if (index < 0) {
          this.days.push({
            day: dayStr,
            count: 1,
          });
        } else {
          this.days[index].count++;
        }
      });
    },
  },
  created() {
    document.addEventListener(
      "click",
      (e) => {
        let element = e.target;
        if (element.localName === "span" && element.classList.contains("tag")) {
          this.filter.tag = element.textContent;
        } else if (element.classList.contains("tag")) {
          setTimeout(() => {
            this.showSummary = false;
          }, 20);
        } else if (this.showSummary) {
          this.showSummary = false;
          e.stopImmediatePropagation();
        }
      },
      true
    );
    this.loading = true;
    Posts.all().then((data) => {
      this.posts = data;
      this.loading = false;
    });
  },

  methods: {
    cache(id, content) {
      Cache.setDebounce(id, content);
    },
    create(content) {
      Cache.flush();
      this.filter.status = Status.INBOX;
      Posts.create(content).then((data) => {
        this.posts.splice(0, 0, data);
        let editor = this.$refs["input"];
        editor.done();
        editor.clear();
        editor.focus();
        Cache.remove(0);
      });
    },
    cancelEdit(post) {
      Cache.flush();
      post.cache = "";
      post.isEditing = false;
      Cache.remove(post.id);
    },
    update(post, index, event) {
      Cache.flush();
      post.content = event;
      post.status = Status.INBOX;
      this.filter.status = Status.INBOX;
      Posts.update(post).then((response) => {
        let editor = this.$refs["input"];
        let i = this.posts.indexOf(post);
        this.posts.splice(i, 1, post);
        editor.done();
        post.isEditing = false;
        Cache.remove(post.id);
      });
    },
    remove(post, index) {
      Cache.remove(post.id);
      if (post.status === Status.DELETED) {
        Posts.delete(post).then(() => {
          let index = this.posts.findIndex((item) => item.id === post.id);
          this.posts.splice(index, 1);
        });
      } else {
        post.status = Status.DELETED;
        Posts.update(post).then(() => {
          let index = this.posts.findIndex((item) => item.id === post.id);
          this.posts.splice(index, 1, post);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./styles/index.scss";
.post-list-move {
  transition: all 0.3s;
}
.post-list-complete-enter,
.post-list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.post-list-leave-active {
  position: absolute;
  box-shadow: none;
  display: none;
  z-index: -1;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
</style>

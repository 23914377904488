import { Post } from "./Beans";
import { IndexedDBManager } from "./db";
export class Posts {
  static _opened = false;
  static db = new IndexedDBManager('nasa', 1, 'posts', () => {
    this.create('hello world')
  });

  static async all () {
    await this.checkConnection();
    return this.db.query();
  }

  static async checkConnection () {
    if (!this._opened) {
      await this.db.open();
      this._opened = true;
    }
  }

  static async create (content) {
    await this.checkConnection();
    const post = Post.of(content);
    post.id = await this.db.add(post);
    return post;
  }

  static async update (post) {
    await this.checkConnection();
    return this.db.update(post)
  }

  static async delete (post) {
    await this.checkConnection();
    return this.db.delete(post.id)
  }
}

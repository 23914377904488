export class IndexedDBManager {
  constructor(dbName, dbVersion, storeName, onCreate = () => { }) {
    this.dbName = dbName;
    this.dbVersion = dbVersion;
    this.storeName = storeName;
    this.onCreate = onCreate;
    this.db = null;
  }

  // 打开或创建数据库
  async open () {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(this.dbName, this.dbVersion);

      request.onerror = (event) => {
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        this.db = event.target.result;
        resolve(this.db);
      };

      request.onupgradeneeded = (event) => {
        this.db = event.target.result;
        if (!this.db.objectStoreNames.contains(this.storeName)) {
          this.db.createObjectStore(this.storeName, { keyPath: 'id', autoIncrement: true });
        }
      };
    });
  }

  // 添加数据
  async add (data) {
    if (!this.db) {
      throw new Error('Database not opened yet.');
    }

    const transaction = this.db.transaction([this.storeName], 'readwrite');
    const objectStore = transaction.objectStore(this.storeName);
    const request = objectStore.add(data);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (errorEvent) => {
        reject(errorEvent.target.error);
      };
    });
  }

  // 删除数据
  async delete (key) {
    if (!this.db) {
      throw new Error('Database not opened yet.');
    }

    const transaction = this.db.transaction([this.storeName], 'readwrite');
    const objectStore = transaction.objectStore(this.storeName);
    const request = objectStore.delete(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (errorEvent) => {
        reject(errorEvent.target.error);
      };
    });
  }

  // 更新数据
  async update (newData) {
    if (!this.db) {
      throw new Error('Database not opened yet.');
    }

    const transaction = this.db.transaction([this.storeName], 'readwrite');
    const objectStore = transaction.objectStore(this.storeName);
    const request = objectStore.put(newData);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (errorEvent) => {
        reject(errorEvent.target.error);
      };
    });
  }

  // 查询数据
  async query (queryOptions = {}) {
    if (!this.db) {
      throw new Error('Database not opened yet.');
    }

    const transaction = this.db.transaction([this.storeName], 'readonly');
    const objectStore = transaction.objectStore(this.storeName);
    let cursorRequest = objectStore.openCursor();

    if (queryOptions.range) {
      cursorRequest = objectStore.openCursor(queryOptions.range);
    }

    return new Promise((resolve) => {
      const results = [];

      cursorRequest.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          results.push(cursor.value);
          cursor.continue();
        } else {
          resolve(results);
        }
      };

      cursorRequest.onerror = (errorEvent) => {
        console.error('Error during querying:', errorEvent.target.error);
      };
    });
  }

  // 关闭数据库（可选）
  close () {
    if (this.db) {
      this.db.close();
    }
  }
}
<template>
  <div class="editor">
    <editor-menu-bubble
      v-show="!readonly"
      :editor="editor"
      :keep-in-bounds="keepInBounds"
      v-slot="{ commands, isActive, menu,getMarkAttrs }"
    >
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >

        <form
          class="menububble__form"
          v-if="linkMenuIsActive"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
        >
          <input
            class="menububble__input"
            type="text"
            v-model="linkUrl"
            placeholder="https://"
            ref="linkInput"
            @keydown.esc="hideLinkMenu"
          />
          <button
            class="menububble__button"
            @click="setLinkUrl(commands.link, null)"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>delete-2-alternate</title>
              <path d="M20.485,3.511A12.01,12.01,0,1,0,24,12,12.009,12.009,0,0,0,20.485,3.511Zm-1.767,15.21A9.51,9.51,0,1,1,21.5,12,9.508,9.508,0,0,1,18.718,18.721Z" />
              <path d="M16.987,7.01a1.275,1.275,0,0,0-1.8,0l-3.177,3.177L8.829,7.01A1.277,1.277,0,0,0,7.024,8.816L10.2,11.993,7.024,15.171a1.277,1.277,0,0,0,1.805,1.806L12.005,13.8l3.177,3.178a1.277,1.277,0,0,0,1.8-1.806l-3.176-3.178,3.176-3.177A1.278,1.278,0,0,0,16.987,7.01Z" />
            </svg>

          </button>
        </form>

        <template v-else>
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.mark() }"
            @click="commands.mark"
          >
            <svg
              viewBox="0 0 16 16"
              version="1.1"
            >
              <title>highlight</title>
              <g
                id="highlight"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <rect
                  id="Rectangle-55"
                  stroke="#FADB14"
                  stroke-width="0.5"
                  fill="#FADB14"
                  x="2"
                  y="12.75"
                  width="12"
                  height="1.5"
                  rx="0.125"
                ></rect>
                <g
                  id="Group-2"
                  transform="translate(2.781250, 1.375000)"
                  fill-rule="nonzero"
                >
                  <path
                    fill="white"
                    d="M2.86079849,6.64817222 L2.05713835,5.84451208 C2.00832281,5.79569655 2.00832281,5.71655092 2.05713835,5.66773539 L3.61029491,4.11457882 L3.11963835,3.62392225 C3.07082281,3.57510672 3.07082281,3.49596109 3.11963835,3.44714556 L6.47839556,0.0883883476 C6.52721109,0.0395728112 6.60635672,0.0395728112 6.65517225,0.0883883476 L11.5165314,4.94974747 C11.5653469,4.998563 11.5653469,5.07770863 11.5165314,5.12652416 L8.15777416,8.48528137 C8.10895863,8.53409691 8.029813,8.53409691 7.98099747,8.48528137 L7.38889678,7.89318068 L5.83574021,9.44633725 C5.78692467,9.49515278 5.70777905,9.49515278 5.65896351,9.44633725 L5.0267407,8.81411444 L4.48856529,9.35326519 C4.39477378,9.44720966 4.26747335,9.5 4.13472392,9.5 L0.608857988,9.5 C0.470786801,9.5 0.358857988,9.38807119 0.358857988,9.25 C0.358857988,9.18363253 0.385247413,9.11998865 0.432210608,9.07309408 L2.86079849,6.64817222 Z M6.56678391,1.67937861 L4.71062861,3.53553391 L8.06938582,6.89429112 L9.92554112,5.03813582 L6.56678391,1.67937861 Z M3.64812861,5.75612373 L5.74735186,7.85534699 L6.54284699,7.05985186 L4.44362373,4.96062861 L3.64812861,5.75612373 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </svg>
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>italic</title>
              <path d="M22.5.248H14.863a1.25,1.25,0,0,0,0,2.5h1.086a.25.25,0,0,1,.211.384L4.78,21.017a.5.5,0,0,1-.422.231H1.5a1.25,1.25,0,0,0,0,2.5H9.137a1.25,1.25,0,0,0,0-2.5H8.051a.25.25,0,0,1-.211-.384L19.22,2.98a.5.5,0,0,1,.422-.232H22.5a1.25,1.25,0,0,0,0-2.5Z" />
            </svg>

          </button>
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>strike-through</title>
              <path d="M23.75,12.952A1.25,1.25,0,0,0,22.5,11.7H13.564a.492.492,0,0,1-.282-.09c-.722-.513-1.482-.981-2.218-1.432-2.8-1.715-4.5-2.9-4.5-4.863,0-2.235,2.207-2.569,3.523-2.569a4.54,4.54,0,0,1,3.081.764A2.662,2.662,0,0,1,13.615,5.5l0,.3a1.25,1.25,0,1,0,2.5,0l0-.268A4.887,4.887,0,0,0,14.95,1.755C13.949.741,12.359.248,10.091.248c-3.658,0-6.023,1.989-6.023,5.069,0,2.773,1.892,4.512,4,5.927a.25.25,0,0,1-.139.458H1.5a1.25,1.25,0,0,0,0,2.5H12.477a.251.251,0,0,1,.159.058,4.339,4.339,0,0,1,1.932,3.466c0,3.268-3.426,3.522-4.477,3.522-1.814,0-3.139-.405-3.834-1.173a3.394,3.394,0,0,1-.65-2.7,1.25,1.25,0,0,0-2.488-.246A5.76,5.76,0,0,0,4.4,21.753c1.2,1.324,3.114,2,5.688,2,4.174,0,6.977-2.42,6.977-6.022a6.059,6.059,0,0,0-.849-3.147.25.25,0,0,1,.216-.377H22.5A1.25,1.25,0,0,0,23.75,12.952Z" />
            </svg>

          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>close-quote</title>
              <path d="M18.559,3.932a4.942,4.942,0,1,0,0,9.883,4.609,4.609,0,0,0,1.115-.141.25.25,0,0,1,.276.368,6.83,6.83,0,0,1-5.878,3.523,1.25,1.25,0,0,0,0,2.5,9.71,9.71,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,18.559,3.932Z" />
              <path d="M6.236,3.932a4.942,4.942,0,0,0,0,9.883,4.6,4.6,0,0,0,1.115-.141.25.25,0,0,1,.277.368A6.83,6.83,0,0,1,1.75,17.565a1.25,1.25,0,0,0,0,2.5,9.711,9.711,0,0,0,9.428-9.95V8.873A4.947,4.947,0,0,0,6.236,3.932Z" />
            </svg>

          </button>

          <button
            class="menububble__button"
            @click="showLinkMenu(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }"
          >
            <!-- <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>hyperlink</title>
              <path d="M12.406,14.905a1,1,0,0,0-.543,1.307,1,1,0,0,1-.217,1.09L8.818,20.131a2,2,0,0,1-2.828,0L3.868,18.01a2,2,0,0,1,0-2.829L6.7,12.353a1.013,1.013,0,0,1,1.091-.217,1,1,0,0,0,.763-1.849,3.034,3.034,0,0,0-3.268.652L2.454,13.767a4.006,4.006,0,0,0,0,5.657l2.122,2.121a4,4,0,0,0,5.656,0l2.829-2.828a3.008,3.008,0,0,0,.651-3.27A1,1,0,0,0,12.406,14.905Z" />
              <path d="M7.757,16.241a1.011,1.011,0,0,0,1.414,0L16.95,8.463a1,1,0,0,0-1.414-1.414L7.757,14.827A1,1,0,0,0,7.757,16.241Z" />
              <path d="M21.546,4.574,19.425,2.453a4.006,4.006,0,0,0-5.657,0L10.939,5.281a3.006,3.006,0,0,0-.651,3.269,1,1,0,1,0,1.849-.764A1,1,0,0,1,12.354,6.7l2.828-2.828a2,2,0,0,1,2.829,0l2.121,2.121a2,2,0,0,1,0,2.829L17.3,11.645a1.015,1.015,0,0,1-1.091.217,1,1,0,0,0-.765,1.849,3.026,3.026,0,0,0,3.27-.651l2.828-2.828A4.007,4.007,0,0,0,21.546,4.574Z" />
            </svg>

          </button>
        </template>

      </div>
    </editor-menu-bubble>

    <editor-content
      class="editor__content"
      :editor="editor"
    />
    <div
      class="controls"
      v-if="!readonly"
    >
      <button
        class="btn-send"
        @click="submit"
        :disabled="!this.content"
        v-loading="submitLoading"
      >记录</button>
      <button
        v-if="!keepAlive"
        class="btn-cancel plain"
        @click="cancel"
      >取消</button>
    </div>

    <div
      class="suggestion-list"
      ref="suggestions"
      v-show="filteredSuggestions.length>0"
    >
      <div
        v-for="(suggestion, index) in filteredSuggestions"
        :key="suggestion"
        class="suggestion-list__item"
        :class="{ 'is-selected': selectedSuggestionIndex === index }"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.substring(1) }}
      </div>
    </div>
  </div>
</template>

<script>
let checkContent = (content) => {
  for (let i = 0; i < content.length; i++) {
    const element = content[i];
    if (typeof element.text == "string" && element.text.trim().length > 0) {
      return true;
    }
    if (element.content instanceof Array) {
      if (checkContent(element.content)) {
        return true;
      }
    }
  }
  return false;
};

import { Editor, EditorContent, EditorMenuBubble, Extension } from "tiptap";
import {
  // Blockquote,
  // CodeBlock,
  HardBreak,
  BulletList,
  // Heading,
  ListItem,
  OrderedList,
  Bold,
  Code,
  Italic,
  // Link,
  Strike,
  Underline,
  History,
  Placeholder,
} from "tiptap-extensions";

import { Mark, Tag, Link, Blockquote } from "./custom";

export default {
  components: {
    EditorContent,
    EditorMenuBubble,
  },
  data() {
    let vm = this;
    return {
      linkUrl: null,
      query: "",
      suggestionRange: null,
      insertTag: () => {},
      selectedSuggestionIndex: 0,
      linkMenuIsActive: false,
      keepInBounds: true,
      content: this.initContent,
      submitLoading: false,
      lastContent: this.initContent,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new Mark(),
          // new CodeBlock(),
          new Tag({
            getItems() {
              return vm.tagSuggestions ? vm.tagSuggestions : [];
            },
            onChange({ items, range, query, virtualNode }) {
              vm.query = query;
              vm.suggestionRange = range;
              vm.selectedSuggestionIndex = 0;
              vm.renderSuggestion(virtualNode);
            },
            onFilter(items, query) {
              if (!query) {
                return items;
              }
              return items.filter((i) => i.indexOf(query) >= 0);
            },
            onEnter({ items, query, range, command, virtualNode }) {
              vm.query = query;
              vm.suggestionRange = range;
              vm.insertTag = command;
              vm.renderSuggestion(virtualNode);
            },
            onExit() {
              vm.query = "";
              vm.selectedSuggestionIndex = 0;
            },
            onKeyDown({ event }) {
              let key = event.key;
              let totalCount = vm.filteredSuggestions.length;
              let index = vm.selectedSuggestionIndex;
              if (key === "ArrowUp") {
                if (index > 0) vm.selectedSuggestionIndex--;
                return true;
              }
              if (key === "ArrowDown") {
                if (index < totalCount - 1) vm.selectedSuggestionIndex++;
                return true;
              }
              if (key === "Enter" && index < totalCount) {
                vm.selectSuggestion(vm.filteredSuggestions[index]);
                return true;
              }
              return false;
            },
          }),
          new HardBreak(),
          // new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new BulletList(),
          new Link({
            target: "_blank",
          }),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            emptyNodeText: "写点什么吧...",
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          new (class extends Extension {
            keys() {
              return {
                ["Ctrl-Enter"]() {
                  console.log(vm);
                  vm.submit();
                  return true;
                },
              };
            }
          })(),
        ],
        content: null,
        onUpdate: this.update,
      }),
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  props: {
    initContent: String,
    keepAlive: Boolean,
    readonly: Boolean,
    tagSuggestions: Array,
  },
  watch: {
    readonly(value) {
      this.updateEditable();
    },
  },
  computed: {
    filteredSuggestions() {
      return this.query
        ? this.tagSuggestions.filter((sug) => sug.indexOf(this.query) >= 0)
        : [];
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.initContent) {
        this.setContent(this.initContent);
      }
      this.updateEditable();
      this.focus();
    });
  },
  methods: {
    renderSuggestion(node) {
      if (this.filteredSuggestions.length) {
        let { x, y } = node.getBoundingClientRect();
        let popup = this.$refs["suggestions"];
        popup.style.top = `${y + 20}px`;
        popup.style.left = x + "px";
      }
    },
    selectSuggestion(suggestion) {
      this.insertTag({
        range: this.suggestionRange,
        attrs: {
          label: suggestion,
        },
      });
      this.editor.focus();
    },
    updateEditable() {
      this.editor.setOptions({
        editable: !this.readonly,
      });
    },
    update(editor) {
      if (!("content" in editor.getJSON().content[0])) {
        this.clear();
        this.$emit("change", "");
        return;
      }
      this.content = editor.getHTML();
      this.$emit("change", this.content);
    },
    clear() {
      this.content = null;
      this.editor.setContent("");
    },
    focus() {
      this.editor.focus();
    },
    setContent(content) {
      this.editor.setContent(content);
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url, target: "__blank" });
      this.hideLinkMenu();
    },
    submit() {
      if (this.content && this.content.trim()) {
        this.submitLoading = true;
        this.lastContent = this.content;
        this.$emit("submit", this.content);
      }
    },
    done() {
      this.submitLoading = false;
    },
    cancel() {
      this.setContent(this.lastContent);
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";
.editor {
  svg {
    width: 16px;
    height: 16px;
    fill: white;
  }

  .controls {
    margin-top: 5px;
    height: 30px;
    .btn-send {
      padding: 6px 16px;
      float: right;
    }
    .btn-cancel {
      padding: 6px 16px;
      float: right;
    }
    button {
      width: 70px;
      & svg {
        display: block;
        margin: auto;
        padding: 0px;
        height: 100%;
        transform: scale(1.8);
      }
    }
  }
}
.editor *.is-empty:nth-child(1)::before,
.editor *.is-empty:nth-child(2)::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.suggestion-list {
  padding: 0.2rem;
  border: 2px solid rgba($color-dark, 0.1);
  font-size: 0.8rem;
  font-weight: bold;
  background-color: $color-dark;
  text-align: inherit;
  color: $color-light;
  border-radius: 5px;
  position: fixed;
  &__item {
    border-radius: 3px;
    padding: 0.1rem 0.4rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: rgba($color-light, 0.2);
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}
</style>

export default class Cache {
  private static lastTimer = 0;
  private static lastId: Number = 0;
  private static save: Function;
  /**
   * 设置缓存
   */
  public static setDebounce(id: Number, content: string) {
    this.save = () => {
      this.lastId = id;
      localStorage.setItem(id.toString(), content)
      this.lastTimer = 0;
    };
    if (this.lastId != id) {
      this.save();
      return;
    }
    if (this.lastTimer) {
      clearTimeout(this.lastTimer);
    }
    this.lastTimer = setTimeout(() => {
      this.save();
    }, 1000);
  }

  /**
   * 立刻完成所有更改
   */
  public static flush() {
    if (this.lastTimer) {
      clearTimeout(this.lastTimer);
    }
    if (this.save) {
      this.save();
    }
  }

  /**
   * 获取缓存
   */
  public static get(id: Number) {
    return localStorage.getItem(id.toString());
  }

  /**
   * 删除缓存
   */
  public static remove(id: Number) {
    localStorage.removeItem(id.toString());
  }
}
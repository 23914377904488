
import Vue from "vue";

let lastShow: any;
export default Vue.extend({
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    document.addEventListener("click", () => {
      if (this.isShow) {
        this.hideMenu();
      }
    });
  },
  methods: {
    showMenu(e: MouseEvent) {
      if (lastShow) {
        lastShow.hideMenu();
      }
      this.isShow = true;
      e.preventDefault();
      let menu = this.$refs["context-menu"] as HTMLElement;
      menu.style.visibility = "visible";
      menu.style.left = e.clientX + "px";
      menu.style.top = e.clientY + "px";
      lastShow = this;
    },
    hideMenu() {
      this.isShow = false;
      let menu = this.$refs["context-menu"] as HTMLElement;
      if (menu && menu.style) {
        menu.style.visibility = "hidden";
      }
    },
  },
});

export class Post {
  id = '';
  uid = '';
  content = '';
  status = 0;
  postTime = '';

  static of(content: string) {
    return {
      uid: 'local',
      content,
      postTime: new Date().toISOString(),
      status: 0,
    } as Post;
  }
}
